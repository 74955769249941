import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
// core components
import meetAvatar from 'assets/images/Home/team.jpg'

import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/pages/Home/teamMeetSectionStyle'
import globalStyles from 'assets/jss/PLUTEX'

const useStyles = makeStyles(styles)
const useGlobalStyles = makeStyles(globalStyles)

const TeamMeetSection = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <Box className={classes.gameContainer}>
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Typography className={classes.title}>MEET THE TEAM</Typography>
        <Box className={classes.featureContainer}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            spacing={7}
            style={{ paddingTop: '10px' }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'center' }}>
              <img src={meetAvatar} className={classes.meetAvatar} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} style={{ alignItems: 'center' }}>
              <Box>
                <Typography className={globalClasses.text17}>
                  Meet our team, a global ensemble of visionaries and experts who have dedicated years to the
                  exploration and development of the Web3 space. Hailing from diverse corners of the world, our
                  members bring a wealth of knowledge, experience, and cultural richness to the table, united by a
                  shared passion for innovation and the transformative potential of blockchain technology.
                </Typography>
                <Typography className={globalClasses.text17}>
                  Each member of our team is a specialist in their field, whether it's smart contract development,
                  digital artistry, blockchain security, or community engagement. Together, we've built a foundation
                  of trust, creativity, and technical excellence that is the bedrock of our project.
                </Typography>
                <Typography className={globalClasses.text17}>
                  We are more than a team; we are a tight-knit community of dreamers and doers, creators and
                  thinkers, united by the belief that NFTs are not just a new form of asset, but a revolutionary way
                  to express, connect, and create value in the digital age. Join us as we continue to push the
                  envelope, crafting immersive experiences and building the future of digital ownership and
                  interaction in the Web3 universe.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default TeamMeetSection
