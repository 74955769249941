// export const REACT_APP_CHAINID = '0x7a69'  //hardhat local
// export const REACT_APP_RPC_URL = 'http://localhost:8545'
////Merlin Test
export const REACT_APP_NETWORK_NAME = "Merlin Testnet"
export const REACT_APP_CHAINID = '0xa7b14'
export const REACT_APP_RPC_URL = 'https://testnet-rpc.merlinchain.io'
export const REACT_APP_BLOCK_EXPLORE_URL = 'https://testnet-scan.merlinchain.io'

export const REACT_APP_NFT_CONTRACT_ADDRESS = '0x73918Eab928F143107468ebd35C74DCCFb17C4F7'
export const NFT_DISCOUNT_PRICE = 0.0000003;
export const NFT_NORMAL_PRICE = 0.0000006;
